// Check if #contacts element is present 
if (document.getElementById('contacts') && document.getElementById('location-selector')) {
    var pageTitle = document.getElementById('location-selector').getAttribute('data-location');

    initializeLocationButtons();
    updateDisplayForActiveLocation();


    function initializeLocationButtons() {
        document.querySelectorAll('.location-button').forEach(function(button) {
            button.addEventListener('click', function() {
                var selectedLocation = button.getAttribute('data-location');
                updateActiveLocation(button);
                updateEntryDetails(selectedLocation);
                updatePeopleList(selectedLocation);
            });
        });
    }

    function updateDisplayForActiveLocation() {
        var activeLocationTitle = document.querySelector('.location-button.active')?.getAttribute('data-location') || pageTitle;
        updateEntryDetails(activeLocationTitle);
        updatePeopleList(activeLocationTitle);
    }

    function updateActiveLocation(clickedButton) {
        var locations = document.querySelectorAll('.location-button');
        locations.forEach(function(location) {
            location.classList.remove('active');
        });
        clickedButton.classList.add('active');
    }

    function updateEntryDetails(locationTitle) {
        var details = document.querySelectorAll('.location-details');
        details.forEach(function(detail) {
            detail.style.display = detail.getAttribute('data-location') === locationTitle ? 'block' : 'none';
        });
    }

    function updatePeopleList(locationTitle) {
        var allPeople = document.querySelectorAll('.person');
        allPeople.forEach(function(person) {
            var personLocation = person.getAttribute('data-location').toUpperCase();
            if (personLocation === locationTitle.toUpperCase()) {
                person.style.display = 'block';
            } else {
                person.style.display = 'none';
            }
        });
    }
}