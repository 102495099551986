window.addEventListener('load', function() {
  function initializeMenu(menuSelector) {
      const menu = document.querySelector(".submenu");
      if (!menu) return;

      document.addEventListener("click", (event) => {
          // Check if the clicked element or its parent is a menu item
          const isMenuItem = event.target.closest(menuSelector);

          if (isMenuItem) {
              // Toggle the submenu only if a menu item is clicked
              menu.classList.toggle("hidden");
              document.body.classList.toggle("overflow-hidden");
              menu.classList.add("fade-in-nav");
          } else if (!event.target.closest(".submenu")) {
              // Hide the submenu if clicking outside of the submenu and menu items
              if (!menu.classList.contains("hidden")) {
                  menu.classList.add("hidden");
                  document.body.classList.remove("overflow-hidden");
              }
          }
      });
  }

  initializeMenu(".has-submenu");
});
