if (document.getElementById('service-index')) {
    
    function filterServices() {
        var selectedLocation = document.getElementById('filterSelect').value;
        var services = document.querySelectorAll('.service-entry');

        services.forEach(function (service) {
            var categories = service.getAttribute('data-categories').split(',');
            if (categories.includes(selectedLocation) || selectedLocation === '') {
                service.style.display = ''; // Show service if it matches the filter or if filter is reset
            } else {
                service.style.display = 'none'; // Hide service if it doesn't match the filter
            }
        });
    }

    document.getElementById('filterSelect').onchange = filterServices;
}