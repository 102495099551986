if (document.getElementById('core-services')) {
    var entryUrl = document.querySelector('#entry-url');
    var locationTitles = document.querySelectorAll('.location-title');
    var categoryItemSelector = document.querySelector('.category-item-selector');
    var categoryItems = document.querySelectorAll('.category-item');
    var categoryInfo = document.querySelectorAll('.category-info');
    var statBlocks = document.querySelectorAll('.stat-block');
    var isFirstChildSet = false;
    var isFirstCategoryInfoSet = false;
    var firstCategoryScrollData = null;
    var locationSelector = document.querySelector('#locationCatSelector');
    var categorySelector = document.querySelector('.category-item-selector');


    function setupDesktopView() {
        locationTitles.forEach(locationTitle => {
            categoryItems.forEach(categoryItem => {
                if (categoryItem.getAttribute('data-location') !== 'BVI') {
                    categoryItem.classList.add('hidden');
                } else if (!isFirstChildSet) {
                    categoryItem.classList.add('heading-active');
                    isFirstChildSet = true;
                    firstCategoryScrollData = categoryItem.getAttribute('scroll-data-category');
                    var onloadCategory = categoryItem.getAttribute('scroll-data-category');
                    entryUrl.setAttribute('href', '/services/' + onloadCategory.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').toLowerCase());
                }
            });

            categoryInfo.forEach(categoryInfoItem => {
                if (categoryInfoItem.getAttribute('data-location') !== 'BVI') {
                    categoryInfoItem.classList.add('hidden');
                } else if (categoryInfoItem.getAttribute('data-category') !== firstCategoryScrollData) {
                    categoryInfoItem.classList.add('hidden');
                } else if (categoryInfoItem.getAttribute('data-category') == firstCategoryScrollData) {
                    categoryInfoItem.classList.remove('hidden');
                }
            });

            statBlocks.forEach(statBlock => {
                if (statBlock.getAttribute('data-location') !== 'BVI') {
                    statBlock.classList.add('hidden');
                }
            });
        });

        var locationListeners = document.querySelectorAll('.location-title');

        locationListeners.forEach(title => {
            title.addEventListener('click', function() {
                var clickedLocation = title.getAttribute('data-location');

                // Disable click events for London and Switzerland
                if (clickedLocation === 'London' || clickedLocation === 'Switzerland') {
                    return; // Exit the function early
                }

                locationTitles.forEach(activeItem => {
                    if (activeItem.classList.contains('active')) {
                        activeItem.classList.remove('active');
                    }
                });

                title.classList.add('active');
                var isFirstChildSetOnChange = false;
                var firstCategoryScrollDataOnChange = null;

                categoryItems.forEach(categoryItem => {
                    if (categoryItem.getAttribute('data-location') !== clickedLocation) {
                        categoryItem.classList.add('hidden');
                    }
                    else {
                        categoryItem.classList.remove('hidden');

                        if (!isFirstChildSetOnChange) {
                            categoryItem.classList.add('heading-active');
                            isFirstChildSetOnChange = true;
                            firstCategoryScrollDataOnChange = categoryItem.getAttribute('scroll-data-category');
                        }
                    }
                });

                categoryInfo.forEach(categoryInfoItem => {
                    if (categoryInfoItem.getAttribute('data-location') !== clickedLocation) {
                        categoryInfoItem.classList.add('hidden');
                    } else if (categoryInfoItem.getAttribute('data-category') == firstCategoryScrollDataOnChange) {
                        categoryInfoItem.classList.remove('hidden');
                    }
                });

                statBlocks.forEach(statBlock => {
                    if (statBlock.getAttribute('data-location') === clickedLocation) {
                        statBlock.classList.remove('hidden');
                    } else {
                        statBlock.classList.add('hidden');
                    }
                });
            });
        });

        if (categoryItemSelector) {
            categoryItemSelector.addEventListener('change', function() {
                var selectedOption = categoryItemSelector.options[categoryItemSelector.selectedIndex];
                var scrollDataCategory = selectedOption.getAttribute('scroll-data-category');

                entryUrl.setAttribute('href', '/services/' + scrollDataCategory.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').toLowerCase());

                categoryInfo.forEach(categoryInfoItem => {
                    if (categoryInfoItem.getAttribute('data-category') === scrollDataCategory && categoryInfoItem.getAttribute('data-location') === selectedOption.getAttribute('data-location')) {
                        categoryInfoItem.classList.remove('hidden');
                    } else {
                        categoryInfoItem.classList.add('hidden');
                    }
                });
            });
        }

        categoryItems.forEach(service => {
            service.addEventListener('click', function() {
                var activeService = service.getAttribute('scroll-data-category');

                entryUrl.setAttribute('href', '/services/' + activeService.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').toLowerCase());
                categoryInfo.forEach(categoryInfoItem => {

                    if (categoryInfoItem.getAttribute('data-category') === activeService && categoryInfoItem.getAttribute('data-location') === service.getAttribute('data-location')) {
                        categoryInfoItem.classList.remove('hidden');
                    } else {
                        categoryInfoItem.classList.add('hidden');
                    }
                });

                categoryItems.forEach(categoryItem => {
                    if (categoryItem === service) {
                        categoryItem.classList.add('heading-active');
                    } else {
                        categoryItem.classList.remove('heading-active');
                    }
                });
            });
        });
    }

    function setupTabletView() {
        // ... Tablet View Setup Code ...
    }

    function setupMobileView() {
        // ... Mobile View Setup Code ...
    }

    // Initial View Setup
    if (window.innerWidth > 1023) {
        setupDesktopView();
    } else if (window.innerWidth < 1023 && window.innerWidth > 767) {
        setupTabletView();
    } else {
        setupMobileView();
    }

    // Resize Event Listener
    window.addEventListener('resize', function() {
        if (window.innerWidth > 1023) {
            setupDesktopView();
        } else if (window.innerWidth < 1023 && window.innerWidth > 767) {
            setupTabletView();
        } else {
            setupMobileView();
        }
    });
}
