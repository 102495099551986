if (document.getElementById('people-search')) {
	console.log('people.js loaded');
	let selectedLocation = '';
	let selectedRole = '';
	const locationElements = document.querySelectorAll('[data-filter-type="location"]');
	const resetFilterBtn = document.getElementById('resetFilterBtn');
	const sortDropdown = document.getElementById('sortDropdown');
	const loadMoreBtn = document.getElementById('loadMoreBtn');
	const showLessBtn = document.getElementById('resetBtn');
	const peopleElements = document.querySelectorAll('.row.person-card');
	let totalPeopleCount = peopleElements.length;
	let initialVisibleCount = Math.min(10, peopleElements.length);
	let visiblePeopleCount = initialVisibleCount;

	locationElements.forEach(element => {
		element.addEventListener('click', function () {
			selectedLocation = this.getAttribute('data-slug');
			updatePeopleDisplay();
		});
	});

	sortDropdown.addEventListener('change', function () {
		sortPeople(this.value);
	});

	resetFilterBtn.addEventListener('click', function () {
		selectedLocation = '';
		selectedRole = '';
		resetDisplay();
	});

	loadMoreBtn.addEventListener('click', function () {
		const hiddenPeople = Array.from(document.querySelectorAll('.row.person-card.hidden'));
		let countLoaded = 0;
		hiddenPeople.forEach(person => {
			if (isPersonDisplayed(person) && countLoaded < 10) {
				person.classList.remove('hidden');
				countLoaded++;
			}
		});
		updateVisiblePeopleCount();
	});

	showLessBtn.addEventListener('click', function () {
		peopleElements.forEach((person, index) => {
			if (index >= initialVisibleCount) person.classList.add('hidden');
		});
		updateVisiblePeopleCount();
	});

	function updatePeopleDisplay() {
		peopleElements.forEach(person => {
			if (isPersonDisplayed(person)) {
				person.classList.remove('hidden');
			} else {
				person.classList.add('hidden');
			}
		});
		updateVisiblePeopleCount();
	}

	function isPersonDisplayed(person) {
		const locationElement = person.querySelector('.person-location');
		const roleElement = person.querySelector('.person-role');
		const isLocationMatch = !selectedLocation || (locationElement && locationElement.textContent.trim().toLowerCase().includes(selectedLocation));
		const isRoleMatch = !selectedRole || (roleElement && roleElement.textContent.trim().toLowerCase() === selectedRole);
		return isLocationMatch && isRoleMatch;
	}

	function resetDisplay() {
		peopleElements.forEach(person => {
			person.classList.remove('hidden');
		});
		updateVisiblePeopleCount();
	}

	function sortPeople(sortType) {
		let container = document.getElementById('container');
		let peopleCards = Array.from(container.getElementsByClassName('person-card'));

		peopleCards.sort((a, b) => {
			let valA, valB;
			if (sortType === 'title') {
				valA = a.querySelector('.person-title') ?. textContent.trim().toLowerCase() || '';
				valB = b.querySelector('.person-title') ?. textContent.trim().toLowerCase() || '';
			} else if (sortType === 'role') {
				valA = a.querySelector('.person-role') ?. textContent.trim().toLowerCase() || '';
				valB = b.querySelector('.person-role') ?. textContent.trim().toLowerCase() || '';
			}
			return valA.localeCompare(valB);
		});

		container.innerHTML = '';
		peopleCards.forEach(card => container.appendChild(card));
		updateVisiblePeopleCount();
	}

	function updateVisiblePeopleCount() {
		visiblePeopleCount = document.querySelectorAll('.row.person-card:not(.hidden)').length;
		toggleLoadMoreButton();
	}

	function toggleLoadMoreButton() {
		if (visiblePeopleCount >= totalPeopleCount) {
			loadMoreBtn.style.display = 'none';
			showLessBtn.style.display = 'block';
		} else {
			loadMoreBtn.style.display = 'block';
			showLessBtn.style.display = 'none';
		}
	}

	toggleLoadMoreButton();
}