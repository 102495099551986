import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import "../src/js/Navigation";
import "../src/js/PeopleIndex";
import "../src/js/FadeElement";
import Menu from "../src/js/Nav-Fade";
import "../src/js/dropdown";
import "../src/js/people";
// import '../src/js/ServiceSelect';
// import '../src/js/ServicesSearch';
// import '../src/js/Timezone'
// import '../src/js/search'
import Headroom from "headroom.js";
import "../src/js/services";
import "../src/js/contacts";
import "../src/js/serviceIndex";

const headroomElement = document.querySelector("#main-header");
const headroom = new Headroom(headroomElement, {
  offset: 50,
});
headroom.init();

document.querySelectorAll(".question").forEach(function (el) {
  el.addEventListener("click", function (e) {
    el.classList.toggle("open");
    el.parentElement.querySelector(".answer").classList.toggle("open");
  });
});
